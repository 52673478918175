import MapImage from '../../../assets/directions/Map.png';

import classes from './Directions.module.css';
import Button from "../../../components/button/Button";
import React from "react";
import {useHistory} from "react-router-dom";

const Directions = () => {

    const history = useHistory();

    return (
        <div className={classes.Container}>
            <div className={classes.Left}>
                <div className={classes.Section}>
                    <div className={classes.Header}>
                        Address
                    </div>
                    <div>
                        <a href="https://www.google.com/maps/place/819+N+49th+St,+Seattle,+WA+98103/@47.6130284,-122.3420645,11z/data=!4m5!3m4!1s0x5490144dffc2de6d:0xb62996830f2397ed!8m2!3d47.663927!4d-122.3477405" target={'_blank'}>
                            819 N 49th St,
                            Seattle, WA 98103
                        </a>

                        <div className={classes.SubDirections}>
                            Pristine Lash Studio is located on the <b>2nd Floor</b> (to the right at the end of the hall)
                        </div>
                    </div>
                </div>
                <div className={classes.Section}>
                    <div className={classes.Header}>
                        Directions
                    </div>
                    <div>
                        Street Parking only (the parking lot is not open to the public)
                        <br />
                        <br />
                        Use the call box located next to the elevator to call “Pristine Lash’ (the elevator will be sent down for you)
                    </div>
                </div>
                <Button className={classes.Button} onClick={() => history.push('/book')}>
                    Book now
                </Button>
            </div>
            <div className={classes.Right}>
                <a href="https://www.google.com/maps/place/819+N+49th+St,+Seattle,+WA+98103/@47.6130284,-122.3420645,11z/data=!4m5!3m4!1s0x5490144dffc2de6d:0xb62996830f2397ed!8m2!3d47.663927!4d-122.3477405" target={'_blank'}>
                    <img src={MapImage} />
                </a>
            </div>
        </div>
    )
}

export default Directions;
