import './App.module.css';
import {withRouter} from 'react-router-dom';
import AppInner from "./AppInner";

const App = () => {
  return (
      <AppInner />
  );
}

export default withRouter(App);
