import classes from './Footer.module.css';
import Instagram from '../../assets/icons/Instagram.svg'
import React from "react";
import Button from "../button/Button";

const Footer = () => {
    return (
        <div className={classes.Container}>
            <div className={classes.ContainerInner}>
                <div className={classes.Section}>
                    <div className={classes.Header}>
                        Contact
                    </div>
                    <div className={classes.Content}>
                        <a href = "mailto: pristinelashllc@gmail.com">pristinelashllc@gmail.com</a>
                    </div>
                </div>
                <div className={classes.Section}>
                    <div className={classes.Header}>
                        Address
                    </div>
                    <div className={classes.Content}>
                        <a href="https://www.google.com/maps/place/819+N+49th+St,+Seattle,+WA+98103/@47.6130284,-122.3420645,11z/data=!4m5!3m4!1s0x5490144dffc2de6d:0xb62996830f2397ed!8m2!3d47.663927!4d-122.3477405" target={'_blank'}>
                            819 N 49th St,
                            Seattle, WA 98103
                        </a>
                    </div>
                </div>
                <div className={classes.Section}>
                    <div className={classes.Content}>
                        <Button className={classes.HoursButton} onClick={() => window.open("https://pristine-lash.square.site")}>Hours</Button>
                    </div>
                </div>
                <div className={[classes.FullSection, classes.TextSmall].join(' ')}>
                    Pristine lash studio located near Greenlake, Fremont, Wallingford, and Ballard in Seattle, WA.
                </div>
                <div className={classes.FullSection}>
                    <a href={'https://www.instagram.com/pristine.lash/'} className={classes.Instagram} target={'_blank'}>
                        <img src={Instagram} alt={'https://www.instagram.com/pristine.lash/'}/>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer;
