import classes from './Services.module.css';
import MegaVolume from '../../assets/services/MegaVolume.png';
import Volume from '../../assets/services/Volume.png';
import Hybrid from '../../assets/services/Hybrid.png';
import Classic from '../../assets/services/Classic.png';
import Footer from "../../components/footer/Footer";
import ServicesSection from "./services/ServicesSection";
import Testimonials from "./testimonials/Testimonials";

const Services = () => {
    return (
        <div className={classes.Container}>
            <div className={classes.ContainerInner}>
                <div className={classes.Showcase}>
                    <div className={classes.ShowcaseOption}>
                        <img src={MegaVolume} />
                        <div className={classes.ShowcaseOptionHeader}>
                            Mega volume
                        </div>
                        <div>
                            Go BIG or go Home! Mega volume is the fullest, darkest, set you can get. Lashes made from (10-20D fans) provide extremely full volume, without the extra weight on your natural lash!
                        </div>
                    </div>
                    <div className={classes.ShowcaseOption}>
                        <img src={Volume} />
                        <div className={classes.ShowcaseOptionHeader}>
                            Volume
                        </div>
                        <div>
                            A little more drama! Volume lashes are created with custom-made (5-10D) fans, giving you more coverage over the eye. If you naturally have less lashes or are just looking for a fuller look this is the perfect set for you!
                        </div>
                    </div>
                    <div className={classes.ShowcaseOption}>
                        <img src={Hybrid} />
                        <div className={classes.ShowcaseOptionHeader}>
                            Hybrid
                        </div>
                        <div>
                            The perfect mix! Hybrid lashes are a great combo of classics and volumes! Achieve a fuller and wispier, yet still natural look with this set.
                        </div>
                    </div>
                    <div className={classes.ShowcaseOption}>
                        <img src={Classic} />
                        <div className={classes.ShowcaseOptionHeader}>
                            Classic
                        </div>
                        <div>
                            The no makeup, makeup look! The most natural looking set of extensions giving you that effortless mascara effect. 1:1 lash ratio where a single extension is applied to each natural eyelash.
                        </div>
                    </div>

                </div>
            </div>

            {/*<ServicesSection />*/}
            {/*<Testimonials />*/}

            <div className={classes.FullList}>
                <div className={classes.FullListInner}>
                    <div className={classes.Section}>
                        <div className={classes.SectionTitle}>Lashes</div>
                        <div>Mega Volume full set</div>
                        <div>Mega Volume fill</div>
                        <div>Volume full set</div>
                        <div>Volume fill</div>
                        <div>Hybrid full set</div>
                        <div>Hybrid fill</div>
                        <div>Classic full set</div>
                        <div>Classic fill</div>
                        <div>Mini fill</div>
                        <div>Foreign fill</div>
                        <div>Lash removal</div>
                        <div>Lash lift</div>
                        <div>Lift and tint</div>
                        <div>Lash or brow tint</div>
                    </div>
                    <div className={classes.Section}>
                        <div className={classes.SectionTitle}>Colored lashes</div>
                        <div>Outer or inner corners</div>
                        <div>Outer and inner corners</div>
                        <div>Full color set</div>
                    </div>
                    <div className={classes.Section}>
                        <div className={classes.SectionTitle}>Specialty</div>
                        <div>Brown lashes, Whispy, Wet lash</div>
                        <div>Please inquire about these services beforehand, additional fees may apply.</div>
                    </div>
                    <div className={classes.Section}>
                        <div className={classes.SectionTitle}>Brows</div>
                        <div>Brow lamination and tint</div>
                        <div>Brow cleanup</div>
                        <div>Full glam</div>
                    </div>
                    <div className={classes.Section}>
                        <div className={classes.SectionTitle}>Waxing</div>
                        <div>Lip wax</div>
                        <div>Chin wax</div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Services;
