import {Route, Switch} from "react-router-dom";
import NavigationBar from "./components/navigation-bar/NavigationBar";
import Services from "./pages/services/Services";
import Policies from "./pages/policies/Policies";
import Home from "./pages/home/Home";
import classes from './App.module.css';
import Book from "./pages/book/Book";
import Store from "./pages/store/Store";


const AppInner = () => {

    return (
        <div className={classes.App}>
            <NavigationBar />
            <Switch>
                <Route path="/services" component={Services} />
                <Route path="/policies" component={Policies} />
                <Route path="/store" component={Store} />
                <Route path="/book" component={Book} />
                <Route path="/" component={Home} />
            </Switch>
        </div>
    )
}

export default AppInner;
