import classes from './About.module.css';
import Paige from '../../../assets/about/Paige.png';
import Preview from '../../../assets/about/Preview.png';
import Preview2 from '../../../assets/about/Preview2.jpeg';


const About = () => {
    return (
        <div className={classes.Container}>
            <div className={classes.ContainerInner}>
                <div className={classes.Section}>
                    <div className={classes.ImageContainer}>
                        <img src={Preview2} />
                    </div>
                    <div className={[classes.Content, classes.ContentRight].join(' ')}>
                        <div>
                            <div className={classes.Header}>
                                Welcome!
                            </div>
                            At Pristine Lash Studio in Seattle, the details matter! This lash and brow studio offers
                            customizable lash extensions, with varying lengths, curls and styled sets to fit your unique
                            needs. Also offered are facial waxing services, brow laminations, lash lifts and tints!
                            We are conveniently located in the center of, Greenlake, Wallingford, Fremont and Ballard.
                            Enhance your natural beauty, while cutting down that morning routine! You will leave Pristine
                            Lash feeling confident and wake up every morning feeling glamorous!
                        </div>
                    </div>
                </div>
                <div className={classes.Section}>
                    <div className={[classes.Content, classes.ContentLeft].join(' ')}>
                        <div>
                            <div className={classes.Header}>
                                Live, Lash, Love
                            </div>
                            Lashes and brows are small things, but such a unique and essential part of our daily beauty
                            routine! Lash extensions are perfect for ANYONE who wants to enhance their natural lash line,
                            reduce their morning routine, or who just wants to wake up feeling glamorous! Whether you
                            appreciate a more natural glam or you live for the drama, we can help figure out the right look for you!
                        </div>
                    </div>
                    <div className={classes.ImageContainer}>
                        <img src={Preview} />
                    </div>
                </div>
                <div className={classes.Section}>
                    <div className={classes.ImageContainer}>
                        <img src={Paige} />
                    </div>
                    <div className={[classes.Content, classes.ContentRight].join(' ')}>
                        <div>
                            <div className={classes.Header}>
                                Nice to meet you!
                            </div>
                            I’m Paige, owner of Pristine Lash! As a licensed Esthetician and Lash tech of 3 years, I
                            have enjoyed every second of my time in the Beauty Industry. It has been so rewarding to be
                            able to help my clients achieve their lash and brow goals and I have loved building lasting
                            relationships with all my lash babes! I am so glad you've chosen Pristine lash and cannot
                            wait to work with you!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
