import React from "react";
import classes from "./NavigationSlide.module.css";
import {useHistory, useLocation} from "react-router-dom";
import Button from "../../../button/Button";


const Option = ({path, text, close}) => {
    const history = useHistory();
    const location = useLocation();

    const isActive = location.pathname === path;

    const onClick = () => {
        history.push(path);
        close();
    }

    return (
        <div className={isActive ? [classes.NavOption, classes.NavOptionActive].join(' ') : classes.NavOption}
             onClick={onClick}>
            {text}
        </div>
    )
}

const NavigationSlide = ({isOpen, close}) =>  {

    const history = useHistory();

    return (
        <>
            {isOpen &&
            <div className={classes.Backdrop} onClick={close}/>
            }
            <div className={isOpen ? [classes.Container, classes.ContainerActive].join(' ') : classes.Container}>
                <div className={classes.Content}>
                    <Option path={'/'} text={'Home'} close={close}/>
                    <Option path={'/services'} text={'Services'} close={close}/>
                    <Option path={'/policies'} text={'Policies'}  close={close}/>
                    <Option path={'/store'} text={'Store'}  close={close}/>
                    <div className={classes.ButtonWrapper}>
                        <Button onClick={() => {history.push('/book'); close();}}>
                            Book now
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}


export default NavigationSlide;
