import React from "react";
import Carousel from "./carousel/Carousel";
import classes from './Home.module.css';
import About from "./about/About";
import Footer from "../../components/footer/Footer";
import Directions from "./directions/Directions";

const Home = () => {
    return (
        <div className={classes.Container}>
            <Carousel />
            <About />
            <Directions />
            <Footer />
        </div>
    )
}

export default Home;
