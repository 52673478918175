import React from 'react';
import classes from './Button.module.css';

const Button = ({className, children, onClick}) => {

    const handleClick = (e) => {
        e.stopPropagation();
        onClick();
    };

    const containerClasses = [classes.Container];
    if (className) {
        containerClasses.push(className);
    }
    return (
        <div className={containerClasses.join(' ')} onClick={handleClick}>
            <div className={classes.Label}>
                {children}
            </div>
        </div>
    );
}

export default Button;
