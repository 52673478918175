import React, {useEffect, useState} from "react";
import DesktopNavigationBarLarge from "./desktop/large/DesktopNavigationBarLarge";
import DesktopNavigationBarSmall from "./desktop/small/DesktopNavigationBarSmall";
import MobileNavigationBar from "./mobile/MobileNavigationBar";
import classes from './NavigationBar.module.css';

const NavigationBar = () => {

    const [isLarge, setIsLarge] = useState(true);
    const [isMobile, setIsMobile] = useState(false);

    const handleScroll = () => {
        const y = window.scrollY;
        setIsLarge(y < 130);
    }

    const handleResize = () => {
        const width = window.innerWidth;
        setIsMobile(width < 800);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        handleResize();
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize)
        }

    }, []);

    if (isMobile) {
        return <MobileNavigationBar />
    } else {
        if (isLarge) {
            return <DesktopNavigationBarLarge />
        } else {
            return <DesktopNavigationBarSmall />
        }
    }
}

export default NavigationBar;
