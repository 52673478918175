import classes from './Carousel.module.css';
import Client1 from '../../../assets/clients/Client1.png';
import Client2 from '../../../assets/clients/Client2.png';
import Client3 from '../../../assets/clients/Client3.png';
import Client4 from '../../../assets/clients/Client4.png';
import Client5 from '../../../assets/clients/Client5.png';
import Client6 from '../../../assets/clients/Client6.png';
import {useState} from "react";
import AngleRight from '../../../assets/icons/AngleRight.svg';
import AngleLeft from '../../../assets/icons/AngleLeft.svg';
import Button from "../../../components/button/Button";

const pages = [
    {
        id: 0,
        clients: [Client1, Client2, Client3],
        title: 'Good lashes, good mood!',
        subtitle: 'Refer a friend and both of you will receive a discount on your next service!'
    },
    {
        id: 1,
        clients: [Client4, Client5, Client6],
        title: 'Happy clients, happy life!',
        subtitle: 'Refer a friend and both of you will receive a discount on your next service!'
    }
]

const Carousel = () => {

    const [activePage, setActivePage] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const increment = () => {
        changePage((activePage + 1) % pages.length);
    }

    const decrement = () => {
        changePage(((activePage - 1) + pages.length) % pages.length);
    }

    const changePage = (id) => {
        setIsTransitioning(true);
        setTimeout(() => {
            setActivePage(id);
            setTimeout(() => {
                setIsTransitioning(false);
            }, 100);
        }, 300);
    }

    const getPageClassName = (page) => {
        if (page !== activePage) {
            return classes.PageHidden;
        }

        if (isTransitioning) {
            return classes.PageTransitioning;
        } else {
            return classes.Page;
        }
    }

    return (
        <div className={classes.Container}>
            <div className={classes.ContainerInner}>
                <div className={classes.Pages}>
                    {pages.map(page => (
                        <div className={getPageClassName(page.id)} key={page.id}>
                            <div className={classes.Images}>
                                {page.clients.map((client, idx) => (
                                    <div className={classes.ImageContainer} key={idx}>
                                        <img src={client} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}

                    <div className={[classes.ArrowLeft, classes.Arrow].join(' ')} onClick={decrement}>
                        <img src={AngleLeft} />
                    </div>
                    <div className={[classes.ArrowRight, classes.Arrow].join(' ')} onClick={increment}>
                        <img src={AngleRight} />
                    </div>
                </div>

                <div className={classes.PageIndicatorContainer}>
                    {pages.map(page => (
                        <div className={page.id === activePage
                            ? [classes.PageIndicatorActive, classes.PageIndicator].join(' ')
                            : classes.PageIndicator} key={page.id}/>
                    ))}
                </div>

                {pages.map(page => (
                    <div className={getPageClassName(page.id)} key={page.id}>
                        <div className={classes.Title}>
                            {page.title}
                        </div>
                        <div className={classes.Subtitle}>
                            {page.subtitle}
                        </div>
                    </div>
                ))}

                <div className={classes.ButtonContainer}>
                    <Button onClick={() => window.open('https://pristine-lash.square.site/')}>
                        Book now
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Carousel;
