import React from "react";
import classes from './Policies.module.css';
import Footer from "../../components/footer/Footer";

const Policies = () => {
    return (
        <div className={classes.Container}>
            <div className={classes.Primary}>
                <div className={classes.ContainerInner}>
                    <div className={classes.Column}>
                        <div className={classes.Section}>
                            <div className={classes.Title}>Fill Policy</div>
                            <div className={classes.Paragraph}>
                                Schedule your appointments accordingly to allow for adequate fill time! 2 weeks is the
                                recommended length to go between fills. For your appointment to be considered a fill it
                                must be no more than 3 weeks since your last appointment. You must have at least 80% of
                                your lashes left or it may be considered an extended fill or even a full set.
                            </div>
                            <div className={classes.Paragraph}>
                                Note: If you're unsure what fill is right for you feel free to send photos to @Pristine.lash on Instagram! (Responses within 24hrs).
                            </div>
                        </div>
                        <div className={classes.Section}>
                            <div className={classes.Title}>Late Policy</div>
                            <div className={classes.Paragraph}>
                                Arrive prior to or on time for your appointment. There will be a 10 minutes grace period
                                before you will be charged a $5 late fee. Anything over 15 minutes (without notice) will
                                be considered a no show your appointment will be cancelled.
                            </div>
                        </div>
                    </div>
                    <div className={classes.Column}>
                        <div className={classes.Section}>
                            <div className={classes.Title}>Cancellations</div>
                            <div className={classes.Paragraph}>
                                Your appointment time is reserved just for you! Last minute cancellations or no shows
                                leaves a gap in the schedule that could have been filled by another client. Be sure to
                                cancel or reschedule your appointment at least 48 hours in advance. Any cancellations or
                                no shows beyond that are subject to a 50% service charge.
                            </div>
                        </div>
                        <div className={classes.Section}>
                            <div className={classes.Title}>Refunds</div>
                            <div className={classes.Paragraph}>
                                ALL service costs and payments are final. I offer complimentary lash fills within three
                                days a 3 day want all my clients to love their lashes! If you want to change or fix your
                                lashes I offer a free 30 minute fill, if notified within 24 hrs of your initial service.
                                However, all service costs and payments are final.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.FooterWrapper}>
                <Footer />
            </div>
        </div>
    )
}

export default Policies;
