import classes from "./DesktopNavigationBarLarge.module.css";
import Logo from "../../../../assets/Logo.svg";
import Instagram from '../../../../assets/icons/Instagram.svg';

import React from "react";
import {useHistory, useLocation} from "react-router-dom";
import Button from "../../../button/Button";

const DesktopNavigationBarLarge = () => {

    const history = useHistory();
    const location = useLocation();

    return (
       <div className={classes.Container}>
           <div className={classes.ContainerInner}>
               <div className={classes.LogoContainer}>
                   <img src={Logo} className={classes.Logo} onClick={() => history.push('/')} alt={'Pristine Lash - Lash extensions'}/>
               </div>
               <div className={classes.Options}>
                   <div className={location.pathname === '/' || location.pathname === '/home' ? [classes.Option, classes.OptionActive].join(' ') : classes.Option} onClick={() => history.push('/home')}>
                       Home
                   </div>
                   <div className={location.pathname === '/services' ? [classes.Option, classes.OptionActive].join(' ') : classes.Option} onClick={() => history.push('/services')}>
                       Services
                   </div>
                   <div className={location.pathname === '/policies' ? [classes.Option, classes.OptionActive].join(' ') : classes.Option} onClick={() => history.push('/policies')}>
                       Policies
                   </div>
                   <div className={location.pathname === '/store' ? [classes.Option, classes.OptionActive].join(' ') : classes.Option} onClick={() => history.push('/store')}>
                       Store
                   </div>
                   <Button className={classes.Button} onClick={() => history.push('/book')}>
                       Book now
                   </Button>
               </div>
           </div>
           <a href={'https://www.instagram.com/pristine.lash/'} className={classes.Instagram} target={'_blank'}>
               <img src={Instagram} alt={'https://www.instagram.com/pristine.lash/'}/>
           </a>
       </div>

   )
}

export default DesktopNavigationBarLarge;
