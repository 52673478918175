import classes from './Store.module.css';
import Cleanser from '../../assets/store/Cleanser.png'
import Product from "./product/Product";
import GiftCard from "../../assets/store/GiftCard.png";
import Footer from "../../components/footer/Footer";

const Store = () => {
    return (
        <div className={classes.Container}>
            <div className={classes.ContainerInner}>
                <Product
                    title={'Lash Cleansers'}
                    subTitle={'Available in Store'}
                    price={'$15'}
                    img={Cleanser}/>
                <Product
                    title={'Gift Cards'}
                    subTitle={'Available online'}
                    price={'$25+'}
                    img={GiftCard}
                    link={'https://squareup.com/gift/ML08TV7ZEW8Y1/order'}/>
            </div>
            <Footer />
        </div>
    )
}

export default Store;
