import classes from './DesktopNavigationBarSmall.module.css';
import {useHistory, useLocation} from "react-router-dom";
import Logo from "../../../../assets/Logo.svg";
import Instagram from "../../../../assets/icons/Instagram.svg";
import React from "react";
import Button from "../../../button/Button";

const DesktopNavigationBarSmall = () => {

    const history = useHistory();
    const location = useLocation();

    return (
        <div className={classes.Container}>
            <div className={classes.ContainerInner}>
                <div className={classes.LogoContainer}>
                    <img src={Logo} className={classes.Logo} onClick={() => history.push('/')} alt={'Pristine Lash - Lash extensions'}/>
                </div>
                <div className={classes.Options}>
                    <div className={location.pathname === '/' || location.pathname === '/home' ? [classes.Option, classes.OptionActive].join(' ') : classes.Option} onClick={() => history.push('/home')}>
                        Home
                    </div>
                    <div className={location.pathname === '/services' ? [classes.Option, classes.OptionActive].join(' ') : classes.Option} onClick={() => history.push('/services')}>
                        Services
                    </div>
                    <div className={location.pathname === '/store' ? [classes.Option, classes.OptionActive].join(' ') : classes.Option} onClick={() => history.push('/store')}>
                        Store
                    </div>
                    <Button className={classes.Button} onClick={() => history.push('/book')}>
                        Book now
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default DesktopNavigationBarSmall;
