import React from 'react';
import classes from './Book.module.css';
import Button from "../../components/button/Button";

const Book = () => {
    return (
        <div className={classes.Container}>
            <div className={classes.ContainerInner}>
                <div className={classes.Note}>
                    <div className={classes.Title}>
                        New clients PLEASE READ prior to your appointment:
                    </div>
                    <div className={classes.Paragraph}>
                        Fill out and sign your <a href={"https://form.jotform.com/240284947450157"} target={"_blank"}>lash consent form</a> or <a href={"https://form.jotform.com/240356773465160"} target={"_blank"}>lash lift form</a>.
                    </div>

                    {/*<div className={classes.Paragraph}>*/}
                    {/*    Step 1: Schedule your appointment!*/}
                    {/*</div>*/}
                    <div className={classes.Paragraph}>
                        Pay your $25 non-refundable deposit. This will be sent via invoice after booking and will go towards your total service amount.
                    </div>

                    <div className={classes.Paragraph}>
                        Arrive a few minutes early to to your appointment start time, to allow adequate time to park,
                        find the studio and receive an initial consultation.
                    </div>
                    <div className={classes.Paragraph}>
                        DO NOT wear eye makeup to your appointment, including mascara, eyeliner and eyeshadow.
                        Avoid using an oil based makeup remover, as well as applying oil based moisturizer or sunscreens
                        around the eye. These oils can prevent the glue from bonding properly with your lash and reduce
                        lash retention. A lash cleansing station is available for you but may take time out of your
                        service. So come prepared!
                    </div>
                    {/*<div className={classes.Paragraph}>*/}
                    {/*    Please use the restroom before your service. Remember to silence your phone and prepare any music*/}
                    {/*    playlist or podcast ahead of time.*/}
                    {/*</div>*/}
                    {/*<div className={classes.Paragraph}>*/}
                    {/*    Your eyes must remain closed for the entirety of your lash service. Opening the eyes can cause*/}
                    {/*    irritation from the glue, will disrupt your service and will slow down the process.*/}
                    {/*    Get ready to lay back, relax and take a nap!*/}
                    {/*</div>*/}


                </div>
                <div className={classes.ButtonWrapper}>
                    <Button onClick={() => window.open('https://pristine-lash.square.site/')}>
                        Book your appointment
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Book;

