import classes from './Testimonials.module.css';
import {useCallback, useState} from "react";
import AngleLeft from '../../../assets/icons/AngleLeft.svg';
import AngleRight from '../../../assets/icons/AngleRight.svg';
import {Swiper, SwiperSlide} from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


const testimonials = [
    {
        id: 0,
        quote: 'After my experience with EWC I really strived to find a new place. I stubbles across you guys and truly felt so welcome and my esthetician Kristin really took the time to help me understand how to use all my after care products. I couldn\'t have asked for a better experience. I already have my next appointment scheduled too! :) can\'t wait to come back!',
        author: 'Paige L.'
    },
    {
        id: 1,
        quote: 'After my experience with EWC I really strived to find a new place. I stubbles across you guys and truly felt so welcome and my esthetician Kristin really took the time to help me understand how to use all my after care products. I couldn\'t have asked for a better experience. I already have my next appointment scheduled too! :) can\'t wait to come back!',
        author: 'Paige L.'
    }
]

const Testimonials = () => {

    const [activeTestimonialId, setActiveTestimonialId] = useState(0);

    const goForward = useCallback(() => {
        setActiveTestimonialId((activeTestimonialId + 1) % testimonials.length);
    }, [activeTestimonialId]);

    const goBackward = useCallback(() => {
        setActiveTestimonialId((activeTestimonialId - 1 + testimonials.length) % testimonials.length);
    }, [activeTestimonialId]);

    return (
        <div className={classes.Container}>

            <Swiper
                slidesPerView={1}
                spaceBetween={0}
                loop={true}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
                speed={500}
            >
                {testimonials.map(testimonial => (
                    <SwiperSlide>
                        <div className={classes.Testimonial}>
                            {testimonial.quote}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            {/*{testimonials.map(testimonial => (*/}
            {/*    <div className={testimonial.id === activeTestimonialId ? classes.Testimonial : [classes.Testimonial, classes.TestimonialHidden].join(' ')}>*/}
            {/*        <div className={classes.TestimonialInner}>*/}
            {/*            {testimonial.quote}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*))}*/}
            {/*<div className={classes.ArrowLeftContainer}>*/}
            {/*    <img src={AngleLeft} />*/}
            {/*</div>*/}
            {/*<div className={classes.ArrowRightContainer}>*/}
            {/*    <img src={AngleRight} />*/}
            {/*</div>*/}
        </div>
    )
}

export default Testimonials;
