import React, {useState} from 'react';
import classes from "./MobileNavigationBar.module.css";
import Logo from "../../../assets/Logo.svg";
import Hamburger from '../../../assets/icons/Hamburger.svg';
import {useHistory} from "react-router-dom";
import Button from "../../button/Button";
import NavigationSlide from "./navigation-slide/NavigationSlide";

const MobileNavigationBar = () => {

    const [isSideNavOpen, setIsSignNavOpen] = useState(false);

    const history = useHistory();


    return (
        <div className={classes.Container}>
            <div className={classes.ContainerInner}>
                <div onClick={() => setIsSignNavOpen(true)}>
                    <img src={Hamburger} className={classes.Hamburger}/>
                </div>
                <div className={classes.LogoContainer}>
                    <img src={Logo} className={classes.Logo} onClick={() => history.push('/')} alt={'Pristine Lash - Lash extensions'}/>
                </div>
                <Button className={classes.Button} onClick={() => history.push('/book')}>
                    Book now
                </Button>
            </div>
            <NavigationSlide isOpen={isSideNavOpen}
                             close={() => setIsSignNavOpen(false)}/>

        </div>
    )
}

export default MobileNavigationBar;
