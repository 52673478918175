import classes from './Product.module.css';

const Product = ({title, subTitle, price, img, link}) => {

    const navigateToLink = () => {
        if (!link) {
            return;
        }
        window.open(link);
    }

    return (
        <div className={!!link ? [classes.Container, classes.ContainerClickable].join(' ') : classes.Container} onClick={navigateToLink}>
            <div className={classes.ImageContainer}>
                <img src={img} />
            </div>
            <div className={classes.Content}>
                <div className={classes.Title}>
                    {title}
                </div>
                <div className={classes.Subtitle}>
                    {subTitle}
                </div>
                <div className={classes.Seperator}/>
                <div className={classes.Price}>
                    {price}
                </div>
            </div>
        </div>

    )
}

export default Product;
